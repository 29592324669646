@charset "utf-8";



// Our variables
$base-font-family: "articulat-cf", Helvetica narrow, Arial;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;
$base-font-weight: 300;
$spacing-unit:     20px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$on-palm:          700px;
$on-desktop:       1200px;



// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

// Customization for base.scss

body,
html {
  height: 100%;
}

body {
  font-weight: $base-font-weight;
  letter-spacing: +0.5px;
  font-style: normal;
}

blockquote {
  letter-spacing: normal;
}

.half-img {
  max-width: 50%;
  margin-left: 8px;
  margin-right: 9px;
}

.site-title {
  letter-spacing: -1px;
}

.icon {

  > svg {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;

    path {
      fill: $grey-color;
    }
  }
}

/**
 * Code formatting
 */
pre,
code {
  border: 0;
  background-color: #eaf0f3;
}

pre {
  overflow-x: auto;
}

ul {
  > li {
    > p {
      margin-bottom: 0;
    }
  }
}
// Customizations for layout.scss

.site-title {
  letter-spacing: normal;
}


.site-nav {
  .page-link {
    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.post-title {
  letter-spacing: normal;
}

.footer-col-1 {
  width: -webkit-calc(30% - (#{$spacing-unit} / 2));
  width:         calc(30% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));  // Use max space so col-3 is pushed on the right
  width:         calc(45% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
}


